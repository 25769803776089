<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Shown below are a set of molecular orbitals formed in diatomic molecules. Determine the
        appropriate orbital label for each cartoon depiction.
      </p>

      <v-simple-table class="mb-7">
        <thead>
          <tr>
            <th style="font-size: 15px; width: 85px">Molecular Orbital</th>
            <th v-for="{label, value} in orbitalTypes" :key="value" style="font-size: 13px">
              <stemble-latex :content="label" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(imageUrl, i) in orbitalImages" :key="imageUrl">
            <td>
              <v-img :src="imageUrl" class="pt-2 pb-1" width="90px" contain />
            </td>
            <td
              v-for="{value} in orbitalTypes"
              :key="`${imageUrl}-${value}`"
              style="text-align: center"
            >
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio :value="value" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question272',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
      orbitalImages: [
        '/img/assignments/DiatomicMO1.png',
        '/img/assignments/DiatomicMO2.png',
        '/img/assignments/DiatomicMO3.png',
        '/img/assignments/DiatomicMO4.png',
        '/img/assignments/DiatomicMO5.png',
        '/img/assignments/DiatomicMO6.png',
      ],
      orbitalTypes: [
        {label: '$\\text{s}$', value: 's'},
        {label: '$\\pi_{\\text{2p}}$', value: 'pi2p'},
        {label: '$\\sigma_{\\text{2p}}$', value: 'sigma2p'},
        {label: '$\\pi^*_{\\text{2p}}$', value: 'pi2pStar'},
        {label: '$\\sigma^*_{\\text{2s}}$', value: 'sigma2sStar'},
        {label: '$\\text{d}$', value: 'd'},
        {label: '$\\sigma_{\\text{2s}}$', value: 'sigma2s'},
        {label: '$\\text{p}$', value: 'p'},
        {label: '$\\sigma^*_\\text{2p}$', value: 'sigma2pStar'},
      ],
    };
  },
};
</script>
